<template>
  <header :class="{ 'scrolled': isScrolled }">
    <div class="container">
      <a href="#"><div class="logo"><img class="logoweb" src="../assets/logoweb.png" alt=""></div></a>
      <nav v-if="!showMenu" class="navText">
        <ul>
          <li><a href="#">domů</a></li>
          <li><a href="#menu">menu</a></li>
          <li><a href="#gallery">galerie</a></li>
          <li><a href="#onas">o nás</a></li>
        </ul>
      </nav>
      <div class="hamburger" @click="toggleMenu">
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
      </div>
      <nav v-if="showMenu" class="mobile-menu">
        <ul>
          <li><a href="#">domů</a></li>
          <li><a href="#menu">menu</a></li>
          <li><a href="#gallery">galerie</a></li>
          <li><a href="#onas">o nás</a></li>
          <li><img class="logowebmobile" src="../assets/logoweb.png" alt=""></li>
        </ul>
      </nav>
    </div>
  </header>
</template>
  
  <script>
  
  
  
  export default {
      name: "navigation",
      components: {
      },
      data(){
        return{
          isScrolled: false,
          showMenu: false
        };
      },
      mounted() {
        window.addEventListener('scroll', this.handleScroll);
      },
      beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
      },
      methods: {
        handleScroll() {
          this.isScrolled = window.scrollY > 0;
        },
        toggleMenu() {
          this.showMenu = !this.showMenu;
        }
      }
  }
  </script>
<style lang="scss" scoped>
.menubutton{
    margin-left: 20em;
    text-transform: uppercase;
	background-color:#000000;
	border-radius:28px;
	border:2px solid #ffffb5;
	cursor:pointer;
	color:#ffffff;
	padding:16px 31px;
    span{
        font-weight: 500;
        font-size: 20px;
    }
    img{
        padding: 0;
        padding-right: 20px;
    }
}
.navText{
  font-size: 25px;
  margin-right: 2em;
  ul{
    gap: 50px;
    display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  }
}
header {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: transparent;
  transition: background-color 0.3s;
}

header.scrolled {
  background-color: rgba(0, 0, 0, 0.658);
}

.container {
  display: flex;
  justify-content: space-between; /* Adjusted to add space between logo and reservation button */
  align-items: center;
  height: 100%;
  padding: 0 30px;
}

.logo {
  color: white;
  font-size: 20px;
}


nav ul li {
  margin-left: 20px;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

nav ul li a:hover {
  color: #EFEFAA; /* Light yellow color on hover */
}

.reservation-button {
  margin-left: auto; /* Aligns the button to the right corner */
}


.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .logoweb{
    display: none;
  }
  .hamburger {
    z-index: 999;
    display: flex;
  }

  nav {
    display: none;
  }

  nav.mobile-menu {
    display: block;
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    background-color: black;
    padding: 20px;
    
  }

  .nav .monile-menu ul{
    display: inline !important;
  }

  nav.mobile-menu ul li {
    text-align: center;
    margin: 10px 0;
  }

  nav.mobile-menu ul li a {
    color: white;
  }

  .logowebmobile{
    width: 25px;
  }
}


@media (min-width: 3840px) {
  .navText{
    font-size: 50px;
  }
  .logoweb{
    width: 70px;
  }
  .container{
    padding: 40px 31px;
  }
}
</style>





