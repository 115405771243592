<template>
  <div class="menu-item">
    <h2>{{ item.name }}</h2>
    <p>{{ item.description }}</p>
  </div>
</template>

<script>
export default {
  props: ['item']
};
</script>

<style>
.menu-item {
  border: 1px solid #ccc;
  padding: 16px;
  margin: 8px 0;
}
</style>