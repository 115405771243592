<template>
    <div class="aboutus">
        <h2 class="title">O nás</h2>
        <img class="underline" src="../assets/underline.svg" alt="">
        <p class="onastext">
            Na sídlišti Slovanka v České Lípě vznikla v 80. letech restaurace. 
            Pro její prosklený vzhled ji sami lidé začali nazývat „SKLENÍK“ a 
            tento název jí zůstal až do současnosti. Přes klasickou a oblíbenou 
            fotbalovou restauraci se v roce 2011 majitel dopracoval k originální 
            myšlence povýšit restauraci na „TANKOVKU SKLENÍK“ a jako první v České 
            Lípě se současně s celkovou rekonstrukcí restaurace zahájilo čepování 
            piva z tanků. Přišel rok 2017 a s ním chuť vyhovovat všem nárokům pro 
            moderní značkovou pivnici. Majitele oslovil atraktivní a jedinečný koncept 
            Plzeňského Prazdroje „KOZLOVNA“, která je projektem značky piva Velkopopovický 
            kozel a spojuje netradiční stylové prostředí s kvalitní kuchyní a tankovým pivem. 
            V České Lípě vzniká „KOZLOVNA SKLENÍK“, která chce svou atmosférou potěšit štamgasty
             a mile překvapit všechny další nové návštěvníky.
        </p>
        <div class="grid-container">
            <div class="grid-item">
                <h3>Poctivé jídlo</h3>
                <img src="../assets/poctivejidlo.png">
                <img class="underlineus1" src="../assets/underline.svg" alt="">
                <p>
                    Skvělá česká kuchyně 
                    s klasickými recepturami
                    od našich předků. Založená
                    na tradičních domácích postupech
                    s důrazem na čerstvost surovin 
                    bez použití dochucovadel a umělých 
                    přísad. Pocit jedinečnosti dodává i
                    naše profesionální obsluha.
                </p>
            </div>
            <div class="grid-item">
                <h3>Tankové pivo</h3>
                <img src="../assets/tankovepivo.png">
                <img class="underlineus2" src="../assets/underline.svg" alt="">
                <p>
                  Naši proškolení výčepní mistři milující pivo čepují vynikající velkopopovickou jedenáctku přímo z tanků, vždy s pěnou hustou jako smetana. Kozel 11 drží díky tradičním výrobním postupům a použití kvalitních českých ingrediencí ochrannou známku České pivo.
                </p>
            </div>
            <div class="grid-item">
                <h3>Originální atmosféra</h3>
                <img src="../assets/originalatmosfera.png">
                <img class="underlineus3" src="../assets/underline.svg" alt="">
                <p>
                  Majestátní socha Kozla, netradiční stoly s kovovými kopýtky nebo i takové detaily jako háčky na oblečení. Všechny tyto elementy ve spojení s materiály dřeva, kůže i kovu navozují příjemnou a nezapomenutelnou atmosféru každé Kozlovny.
                </p>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'App',
    components: {
    },
  }
  
  </script>
  
  <style>
  
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");

  .aboutus{
    padding-bottom: 90em;
    background-color: black;
  }

  .title{
    text-transform: uppercase;
    font-size: 56px;
    left: 45%;
    color: white;
    position: absolute;
}

.underline{
    margin-top: 4.5em;
    left: 44.5%;
    position: absolute;
}

.onastext{
  text-align: center;
    max-width: 1050px;
    font-size: 19px;
    margin-top: 12em;
    left: 25%;
    color: white;
    position: absolute;
}

.underlineus1, .underlineus2, .underlineus3{
    position: absolute;
    margin-left: -16.5em;
}

.grid-container {
    position: absolute;
    margin-top: 40em;
    left: 13%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
}

.grid-item {
    color: white;
  border: 1px solid black;
  padding: 14px;
  text-align: center;
}

.grid-item p{
    max-width: 1400px;
}

@media (min-width: 3840px) {
  .underline{
    display: none;
  }
  .title{
    font-size: 100px;
  }

  .onastext{
    max-width: 2000px;
    font-size: 36px;
  }

  .grid-container{
    margin-top: 60em;
    left: 25%;
  }
  .grid-item h3{
    font-size: 35px;
  }
  .grid-item p{
    font-size: 30px;
    max-width: 2000px;
  }
  .underlineus1, .underlineus2, .underlineus3{
    display: none;
  }
}

@media screen and (max-width: 1450px) {
  .onastext{
    left: 18%;
  }

  .grid-container{
    left: 0;
  }
}


@media screen and (max-width: 1350px) {
  .onastext{
    left: 12%;
  }
}
@media screen and (max-width: 1100px){
  .aboutus{
    padding-top: 10em;
  }

  .onastext{
    left: 3%;
  }

  .grid-container{
    left: 26%;
    display: initial;
  }

  .grid-item p{
    max-width: 500px;
  }
}

@media screen and (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }

  .onastext{
    padding-right: 24px;
    padding-left: 40px;
  }

  .grid-item{
    padding-right: 200px;
  }
}


@media screen and (max-width: 480px) {

  .aboutus{
    padding-top: 40em;
  }
  .grid-container{
    padding-top: 20em;
    left: 0%;
  }
  .grid-item{
    padding-right: 5px;

  }

  .title{
    left: 35%;
}

.underline{
    margin-top: 4.5em;
    left: 33.5%;
}
}
@media screen and (max-width: 430px) {
  .grid-item{
    padding-right: 0;
    
  }

  .grid-container{
    padding-top: 24em;
    left: 0;

  }

  .grid-item p{
    padding-right: 10px;
  }
}


@media screen and (max-width: 384px) {

  .grid-container{
    padding-top: 35em;
  }

}




@media screen and (max-width: 375px) {

  .grid-container{
    padding-top: 0;
    margin-top: 15em;
  }
  .onastext{
    font-size: 16px;
    display: none;
  }
  .underline{
    left: 29.5%;
}

.title{
    left: 30%;
}
}



@media screen and (max-width: 320px) {

  .aboutus{
    margin-top: 10em;
  }

  .underlineus1, .underlineus2, .underlineus3{
    margin-left: -9em;
    width: 100px !important;
  }

  .grid-item{
    margin-left: 30px;
    padding: 45px;
  }
  .grid-item p{
    max-width: 262px;
  }
  .grid-item img{
    width: 200px;
  }

  .grid-container{
    margin-top: 10em;
    padding-top: 0;
  }

  .onastext{
    display: none;
  }
}


  </style>
  