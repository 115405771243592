<template>
  <div class="menu">
    <h1>MENU</h1>
    <div class="buttons-container">
      <button v-for="(url, index) in apiUrls" :key="index" @click="changeActiveDiv(index + 1)" class="menubutton">
        {{ getButtonTitle(index + 1) }}
      </button>
    </div>
    <div class="menu-sections-container">
      <div v-for="(url, index) in apiUrls" :key="index" v-show="isActiveDiv(index + 1)" class="menu-sections">
        <div v-if="getSectionsByUrl(url).length > 0">
          <div v-for="section in getSectionsByUrl(url)" :key="section.id" class="section">
            <h2>{{ section.title }}</h2>
            <ul class="items-container">
              <li v-for="item in section.items" :key="item.name" class="item">
                <span class="item-details">
                  {{ item.name }}{{ item.allergens }} <br>
                  {{ item.description }}
                </span>
                <span class="item-amount" v-if="item.name == 'NÁPOJE'">
                </span>
                <span class="item-amount" v-else><b>{{ item.amount }} Kč</b></span>
              </li>
            </ul>
          </div>
        </div>
        <div v-else>
          <p>Žádné itemy</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuItem from './MenuItem.vue';
import PageTurner from './PageTurner.vue';

export default {
  components: { MenuItem, PageTurner },
  name: "Menu",
  data() {
    return {
      menuSections: [],
      activeDiv: 1,
      apiUrls: [
        'https://vytvormenu-api.prazdrojonline.cz/api/standard-menu/440/xml/', // Denní
        'https://vytvormenu-api.prazdrojonline.cz/api/standard-menu/690/xml/', // Stálá
        'https://vytvormenu-api.prazdrojonline.cz/api/standard-menu/620/xml/' // Nápojový
      ],
    };
  },
  methods: {
    fetchXMLData() {
      const promises = this.apiUrls.map(url => {
        return fetch(url)
          .then(response => response.text())
          .then(xml => {
            this.parseXmlData(xml, url); // Pass URL to parseXmlData
          })
          .catch(error => {
            console.error('Error fetching XML:', error);
          });
      });

      Promise.all(promises)
        .then(() => {
          // All data fetched and parsed
        })
        .catch(error => {
          console.error('Error fetching XML:', error);
        });
    },
    parseXmlData(xml, url) {
      try {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xml, 'text/xml');

        const sections = xmlDoc.querySelectorAll('section[type="dishes"]');

        sections.forEach((section, index) => {
          const title = section.querySelector('title').textContent.trim();
          const items = section.querySelectorAll('item');

          const sectionItems = Array.from(items).map(item => ({
            name: item.querySelector('name').textContent.trim(),
            quantity: item.querySelector('quantity').textContent.trim(),
            amount: item.querySelector('prices price amount').textContent.trim(),
            allergens: item.querySelector('allergens').textContent.trim(),
            description: item.querySelector('description').textContent.trim(),
          }));

          this.menuSections.push({
            id: index + 1,
            title: title,
            items: sectionItems,
            url: url // Store URL for each section
          });
        });
      } catch (error) {
        console.error('Error parsing XML data:', error);
      }
    },
    changeActiveDiv(divNumber) {
      this.activeDiv = divNumber;
    },
    isActiveDiv(divNumber) {
      return this.activeDiv === divNumber;
    },
    getSectionsByUrl(url) {
      return this.menuSections.filter(section => section.url === url);
    },
    getButtonTitle(index) {
      switch (index) {
        case 1:
          return "Denní nabídka";
        case 2:
          return "Stálá nabídka";
        case 3:
          return "Nápojový lístek";
        default:
          return "";
      }
    }
  },
  created() {
    this.fetchXMLData();
  }
};
</script>

<style scoped>


h1{
  color: white;
}

.menubutton {
  text-transform: uppercase;
  background-color: #000000;
  border-radius: 28px;
  border: 2px solid #ffffb5;
  cursor: pointer;
  color: #ffffff;
  padding: 16px 31px;
}

.menubutton:hover {
  color: #ffffb5;
}


.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttons-container {
  margin-bottom: 20px;
  text-align: center;
}

.buttons-container button {
  margin: 5px;
}

.menu-sections-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.menu-sections {
  padding-left: 10px;
  padding-right: 10px;
  width: 70%;
  margin: 20px 0;
}

.section {
  color: white;
  margin-bottom: 30px;
}

h2 {
  padding: 10px;
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 10px;
  text-align: left;
}

.items-container {
  list-style-type: none;
  padding: 0;
}

.item {
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 18px;
  padding-bottom: 10px;
  border-bottom: 1px #EFEFAA dotted;
}

.item-details {
  flex-grow: 1;
}

.item-amount {
  flex-shrink: 0;
  margin-left: 10px;
}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .menu{
  margin-top: 45em;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .menu{
  margin-top: 30em;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .menu{
  margin-top: 20em;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .menu{
  margin-top: 10em;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .menu{
  margin-top: 5em;
  }
}
</style>
