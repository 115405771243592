import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyAQLyoBJkSmb7eMAf4AbLLoniCROd9p2bg",
  authDomain: "galaxie-dc1a3.firebaseapp.com",
  databaseURL: "https://galaxie-dc1a3-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "galaxie-dc1a3",
  storageBucket: "galaxie-dc1a3.appspot.com",
  messagingSenderId: "391303372310",
  appId: "1:391303372310:web:af15bf69b63899e5215f37",
  measurementId: "G-XWHWXYDPFG"
};




const firebaseApp = initializeApp(firebaseConfig);
const database = getDatabase(firebaseApp);

export default firebaseApp;
export { database };