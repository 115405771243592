<template>
  <div class="home">
    <Navigation />
    <div class="hero">
        <h1 class="title">Nejlepší tankové pivo a výborné jídlo na jednom místě
        </h1>
        <span>Ne - Čt: 11:00 - 22:00
              <br>Pá, So:  11:00 - 24:00
        </span>
        <div class="orderby">
          <a href="https://www.foodora.cz/restaurant/d2ug/kozlovna-sklenik"><img class="foodora" src="../src/assets/foodora-logo.png" alt=""></a>
          <a href="#kontakt"><img class="rezervaceonline" src="../src/assets/rezervace-online.png" alt=""></a>
        </div>
        
        <img src="../src/assets/hero.png" class="heroimg" alt="">
    </div>
        
    <Menu id="menu" :apiUrls="apiUrls" />
    <Aboutus id="onas"/>
    <Saloon id="saloon"/>
    <Gallery id="gallery"/>
    <div id="kontakt"></div>
    <Form/>
    <Footer id="kam"/>
  </div>
</template>

<script>

import Navigation from "./components/Navigation";
import Menu from "./components/Menu";
import Aboutus from "./components/AboutUs"
import Gallery from "./components/Gallery";
import Form from "./components/Form"
import Footer from "./components/Footer"
export default {
  name: 'App',
  components: {
    Navigation, Menu, Aboutus, Form, Gallery, Footer
  },
  data(){
    apiUrls: [
        'https://vytvormenu-api.prazdroj.cz/api/standard-menu/440/xml/', // Denní
        'https://vytvormenu-api.prazdroj.cz/api/standard-menu/690/xml/', // Stálá
        'https://vytvormenu-api.prazdroj.cz/api/standard-menu/620/xml/' // Nápojový
      ];
  }
};

</script>

<style>

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");
body {
  background: black;
  margin: 0;
  font-family: 'Poppins', arial;
}


html{
  background-color: white;
  scroll-behavior: smooth;
}


#gallery{
  background-color: black;
}

.title{
  
  margin-top: -20px;
}


.yellowish{
  color: #EFEFAA;
}

.hero{
  background-color: black;
}

.hero span{
  font-size: 25px;
  color: white;
  position: absolute;
  left: 43%;
  top: 75%;
}
.podtitle{
  position: absolute;
  left: 45%;
  color: #EFEFAA;
  top: 85%;
  
}
.hero h1{
  text-align: center;
  text-transform: uppercase;
  color: white;
  max-width: 800px;
  position: absolute;
  font-size: 80px;
  top: 20%;
  left: 30%;
}
.heroimg{
  width: 100%
}

.foodora{
  width: 170px !important;
  position: absolute;
  top: 93%;
  left: 35%;
}

.rezervaceonline{
  width: 170px !important;
  position: absolute;
  top: 93%;
  left: 55%;
}


@media (min-width: 3840px) {
  .hero h1{
    max-width: 1500px;
    font-size: 150px;
  }

  .hero span{
    font-size: 60px;
  }


  .rezervaceonline, .foodora{
    width: 350px !important;
  }
}



@media screen and (max-width: 900px){
  .hero h1{
    left: 20%;
  }
}

@media screen and (max-width: 768px){
  .rezervaceonline{
    left: 57% !important;
  }
}
@media screen and (max-width: 480px){
  .hero h1{
    font-size: 45px;
  }
  .rezervaceonline{
    left: 54% !important;
  }
  .foodora{
    left: 7%;
  }
}


@media screen and (max-width: 430px){
  .hero h1{
    font-size: 40px;
    left: 15%;
    max-width: 300px;
  }

  .hero{
    padding-bottom: 10em;
  }
  .hero span{
    left: 25%;
  }
}


@media screen and (max-width: 384px) {
  .hero h1{
    font-size: 40px;
  }

  .rezervaceonline, .foodora{
    width: 150px !important;
  }
}

@media screen and (max-width: 375px) {

  .hero h1{
    font-size: 34px;
  }
}


@media screen and (max-width: 320px) {
  .hero h1{
    font-size: 38px;
  }
  .rezervaceonline, .foodora{
    width: 100px !important;
  }

  .rezervaceonline{
    top: 70%;
    left: 35% !important;
  }
  .foodora{
    left: 35%;
  }
}

</style>
