<template>
  <div class="aboutus">
        <h2 class="title">Galerie</h2>
        <img class="underline" src="../assets/underline.svg" alt="">
        <div>
            <ul class="buttony">
                <li><button @click="showDiv(1)" :class="{ activebutton: activeDiv === 1 }" class="menubutton">Kozlovna</button></li>
                <li><button @click="showDiv(2)" :class="{ activebutton: activeDiv === 2 }" class="menubutton">Jídla</button></li>
            </ul>
        </div>
        <div class="grid-container" >
            <div class="kozlovna" :class="{ active: activeDiv === 1 }">
                <div class="image-gallery" >
                    <div v-for="(image, index) in kozlovna" :key="index" class="image-wrapper">
                        <img :src="image.path" alt="Image" class="image" @click="openImageKozlovna(index)">
                    </div>
                </div> 
            </div>
            <div class="jidla" :class="{ active: activeDiv === 2 }">
                <div class="image-gallery" >
                    <div v-for="(image, index) in jidla" :key="index" class="image-wrapper">
                        <img :src="image.path" alt="Image" class="image" @click="openImageJidla(index)">
                    </div>
                </div>
            </div>
             
            
            <div v-if="isEnlargedJidla" class="enlarged-image-container" @click="closeImageJidla" >
                <img :src="enlargedImage" :alt="jidla[currentImageIndex].alt" class="enlarged-image">
                <div class="arrow left" @click.stop="prevImage" v-show="currentImageIndex > 0">&#8249;</div>
                <div class="arrow right" @click.stop="nextImage" v-show="currentImageIndex < jidla.length - 1">&#8250;</div>
            </div>
            <div v-if="isEnlargedKozlovna" class="enlarged-image-container" @click="closeImageKozlovna" >
                <img :src="enlargedImage" :alt="kozlovna[currentImageIndex].alt" class="enlarged-image">
                <div class="arrow left" @click.stop="prevImage" v-show="currentImageIndex > 0">&#8249;</div>
                <div class="arrow right" @click.stop="nextImage" v-show="currentImageIndex < kozlovna.length - 1">&#8250;</div>
            </div>
        </div>
    </div>
</template>

<script>

import image1 from "@/assets/gallery/image1.jpg"
import image2 from "@/assets/gallery/image2.jpg"
import image3 from "@/assets/gallery/image3.jpg"
import image4 from "@/assets/gallery/image4.jpg"
import image5 from "@/assets/gallery/image5.jpg"
import image6 from "@/assets/gallery/image6.jpg"
import image7 from "@/assets/gallery/image7.jpg"
import image8 from "@/assets/gallery/image8.jpg"
import image9 from "@/assets/gallery/image9.jpg"
import image10 from "@/assets/gallery/image10.jpg"
import image11 from "@/assets/gallery/image11.jpg"
import image12 from "@/assets/gallery/image12.jpg"
import image13 from "@/assets/gallery/image13.jpg"
import image14 from "@/assets/gallery/image14.jpg"
import image15 from "@/assets/gallery/image15.jpg"
import image16 from "@/assets/gallery/image16.jpg"
import image17 from "@/assets/gallery/image17.jpg"
import image18 from "@/assets/gallery/image18.jpg"
import image19 from "@/assets/gallery/image19.jpg"
import image20 from "@/assets/gallery/image20.jpg"
import image22 from "@/assets/gallery/image22.jpg"
import image23 from "@/assets/gallery/image23.jpg"
import image24 from "@/assets/gallery/image24.jpg"
import { computed } from 'vue'
export default {
  data() {
    return {
      kozlovna: [
        { url: "image1.jpg", path: image1 },
        { url: "image2.jpg", path: image2 },
        { url: "image3.jpg", path: image3 },
        { url: "image4.jpg", path: image4 },
        { url: "image7.jpg", path: image7 },
        { url: "image8.jpg", path: image8 },
        { url: "image9.jpg", path: image9 },
        { url: "image10.jpg", path: image10 },
        { url: "image11.jpg", path: image11 },
        { url: "image12.jpg", path: image12 },
        { url: "image13.jpg", path: image13 },
        { url: "image14.jpg", path: image14 },
        // Add more image objects as needed
      ],
      jidla: [
        { url: "image5.jpg", path: image5 },
        { url: "image6.jpg", path: image6 },
        { url: "image15.jpg", path: image15 },
        { url: "image16.jpg", path: image16 },
        { url: "image17.jpg", path: image17 },
        { url: "image18.jpg", path: image18 },
        { url: "image19.jpg", path: image19 },
        { url: "image20.jpg", path: image20 },
        { url: "image22.jpg", path: image22 },
        { url: "image23.jpg", path: image23 },
        { url: "image24.jpg", path: image24 },
        // Add more image objects as needed
      ],
      activeDiv: 1,
      currentImageIndex: null,
      isEnlargedJidla: false,
      isEnlargedKozlovna: false,
    };
  },
  methods: {
    openImageJidla(index) {
      this.currentImageIndex = index;
      this.isEnlargedJidla = true;
    },
    openImageKozlovna(index) {
      this.currentImageIndex = index;
      this.isEnlargedKozlovna = true;
    },
    showDiv(divNumber) {
      this.activeDiv = divNumber;
    },
    closeImageJidla() {
      this.isEnlargedJidla = false;
    },
    closeImageKozlovna() {
      this.isEnlargedKozlovna = false;
    },
    nextImage() {
      if (this.currentImageIndex < this.jidla.length - 1) {
        this.currentImageIndex++;
      }
    },
    prevImage() {
      if (this.currentImageIndex > 0) {
        this.currentImageIndex--;
      }
    },
  },
  computed:{
    enlargedImage() {
        if (this.activeDiv == 1 && this.currentImageIndex !== null ) {
            return this.kozlovna[this.currentImageIndex].path;
        }else{
            return this.jidla[this.currentImageIndex].path;
        }
      return '';
    },
  }
};
</script>

<style lang="scss" scoped>

.kozlovna, .jidla{
    display: none;
}
button.activebutton{
    color: black;
    background-color: #ffffb5
}
.buttony{
  z-index: 999;
    margin-top: 15em;
    left: 5.5%;
    position: absolute;
    list-style-type: none;
    display: flex;
  }

  
.menubutton{
    margin-left: 35em;
    text-transform: uppercase;
	background-color:#000000;
	border-radius:25px;
	border:2px solid #ffffb5;
	cursor:pointer;
	color:#ffffff;
	padding:15px 25px;
    span{
        font-weight: 500;
        font-size: 15px;
    }
    img{
        padding: 0;
        padding-right: 20px;
    }
}

.active {
    display: unset;
}


.grid-container{
    margin-top: 20em;
    left: 12.5%;
}
.image-gallery {
    margin-left: 10em;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
}

.image-wrapper {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.image {
  width: 100%;
  height: auto;
  transition: transform 0.3s;
}

.image:hover {
  transform: scale(1.1);
}


/* Navigation Arrows */
.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  transition: opacity 0.3s;
}

.arrow:hover {
  opacity: 1;
}

.left {
  left: 5px;
}

.right {
  right: 5px;
}

/* Enlarged Image */
.enlarged-image-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.enlarged-image {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.underline{
  left: 46%;
    position: absolute;
}


@media (min-width: 3840px) {
  .aboutus{
    margin-top: 50em;
  }
}

@media screen and (max-width: 1450px) {
  .image-gallery{
    margin-left: 4em;
  }
  .buttony{
    left: -7.5%;
  }
}


@media screen and (max-width: 1450px) {
  .image-gallery{
    margin-left: 0;
  }

  .buttony{
    left: -10%;
  }
}

@media screen and (max-width: 1100px){
  #gallery{
    padding-top: 94em;
    padding-bottom: 65em;
  }

  .image-gallery{
    margin-left: -6.2em;
    grid-template-columns: repeat(auto-fit, minmax(245px, 1fr));
  }

  .buttony{
    left: -27%;
  }
  
}
@media screen and (max-width: 900px) {
  .buttony{
    left: -46%
  }

  .image-gallery{
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media screen and (max-width: 768px) {
  .image-gallery {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .menubutton{
    margin-left: 25em;
  }
}

@media screen and (max-width: 480px) {
  .title{
    left: 25% !important;
  }

  #gallery{
    padding-top: 115em;
    padding-bottom: 190em;
  }
  .menubutton{
    margin-left: 13em;
  }
  .image-gallery {
    padding-left: 88px;
    padding-right: 41px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .grid-container{
    margin-top: 3em;
  }
}
@media screen and (max-width: 428px) {
  .menubutton{
    margin-left: 11em;
  }
  .underline{
    left: 33%;
  }
}
@media screen and (max-width: 384px) {
  

  #gallery{
    padding-top: 120em;
  }
}

@media screen and (max-width: 375px) {
  #gallery{
    padding-bottom: 220em;
    padding-top: 65em;
  }
  .image-gallery{
    margin-top: 20em;
    padding-left: 70px;
    padding-right: 10px;
  }

  .buttony{
    left: -15%;
  }
}


@media screen and (max-width: 375px) {


.menubutton{
  font-size: 9px;
  padding: 9px;
}
.buttony{
  padding-right: 60px;
  left: -15%;
}
}

@media screen and (max-width: 320px) {

  .title{
    left: 25% !important;
  }

  .buttony{
    padding-right: 0;
  }

  #gallery{
    padding-bottom: 155em;
    padding-top: 55em;
  }

  .image-gallery{
    margin-top: 20em;
    padding-left: 123px;
    padding-right: 25px;
  }
}


</style>