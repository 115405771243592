<template>
    <div class="page-turner">
      <button @click="prevPage" :disabled="currentPage === 1">◀</button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages">▶</button>
    </div>
  </template>
  
  <script>
  export default {
    props: ['currentPage', 'totalPages'],
    methods: {
      prevPage() {
        if (this.currentPage > 1) {
          this.$emit('page-changed', this.currentPage - 1);
        }
      },
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.$emit('page-changed', this.currentPage + 1);
        }
      }
    }
  };
  </script>
  
  <style>
  .page-turner {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
  }
  .page-turner button {
    color: white;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  .page-turner span {
    font-size: 16px;
  }
  </style>