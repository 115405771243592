<template>
  <section id="kontakt" class="kontakt">

    <h2 class="title">Online Rezervace</h2>
        <img class="underline" src="../assets/underline.svg" alt="">
    
    <div class="form-container">
      <span class="po14"><b>REZERVACE NA TENTÝŽ DEN PO 14HOD POUZE PŘES TELEFONNÍ ČÍSLO: 727 884 799</b></span> 
      <form @submit.prevent="makeReservation">
        <div class="column">
          <div class="text writing">
            <label for="firstName">Jméno:</label>

            <input type="text" id="firstName" name="Jméno" v-model="reservation.firstName" required>
            <span></span>
          </div><br>
          <div class="text writing">
            <label for="phone">Telefon:</label>
            <input class="input-style" type="tel" id="phone" v-model="reservation.phone" @input="formatTelephoneNumber" pattern="\d{3} \d{3} \d{3}" placeholder="Formát: XXX XXX XXX" required>
            <span></span>
          </div><br>
          <div class="text writing">
            <label for="Email">Email:</label>

            <input class="input-style" type="email" id="email" v-model="reservation.email" required>
            <span></span>
          </div><br>
          
          
          <a href=""></a>
        </div>
        <div class="date">
          <div class="fecha">
            <input type="date" id="date" name="Datum" v-model="reservation.date" required>
            <span></span>
          </div>
        </div>
        <div class="select">
              <label class="titulo">Čas:</label>
              <div class="view">
                <select id="time" name="čas" onclick="applyOpenStyle(this)" onblur="removeOpenStyle(this)" v-model="reservation.time">
                  <option value="11:00" selected>11:00</option>
                  <option value="12:00">12:00</option>
                  <option value="13:00">13:00</option>
                  <option value="14:00">14:00</option>
                  <option value="15:00">15:00</option>
                  <option value="16:00">16:00</option>
                  <option value="17:00">17:00</option>
                  <option value="18:00">18:00</option>
                  <option value="19:00">19:00</option>
                  <option value="20:00">20:00</option>
                  <option value="21:00">21:00</option>
                  <option value="22:00">22:00</option>
                  <option value="23:00">23:00</option>
                  <option value="24:00">24:00</option>
                </select>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
              </div>
            </div>
        <div class="number">
          <label class="titulo">Počet míst(Max 20):</label>
          <div class="numero">
            <label class="decrement" @click="decrementValue">
              <svg viewBox="0 0 448 512" fill="black">
                <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/>
              </svg>
            </label>
            <input type="number" v-model="reservation.seats" id="numero" name="numero" min="1" max="20">
            <label class="increment" @click="incrementValue">
              <svg viewBox="0 0 448 512" fill="black">
                <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/>
              </svg>
            </label>
          </div>
        </div><br>
        <input  type="checkbox"  v-model="reservation.check" >
          <label class="checkbox" for="checkbox"><b>Nepřeji si, aby mi byla obchodní sdělení zasílána.</b></label><br><br>
          <div>
            <div class="clickable-text" @click="showText = !showText">
              Informace o zpracování Vašich osobních údajů a Vaše možnost odmítnout obchodní sdělení >
            </div>
            <transition name="slide">
              <div v-if="showText" class="sliding-text" key="sliding-text">
                <p class="sdeleni">
                  Odesláním požadavku na rezervaci dojde ke zpracování Vašich osobních údajů, které jste nám v této souvislosti poskytl/a. Společnými správci Vašich dat jsou: Plzeňský Prazdroj, a.s., IČ: 45357366, U Prazdroje 64/7, 301 00, Plzeň – Východní Předměstí (dále také jako "správce 1") a provozovatel KOZLOVNY dále tak jako „správce 2“).<br>
                  <br>Účelem zpracování Vašich výše uvedených osobních údajů (a dalších běžných osobních údajů k nim přirazených např. IP adresa, preferované a čerpané služby) je realizace rezervace objednané služby. Vaše osobní údaje proto zpracováváme z důvodu uvedeného v čl. 6 odst. 1 písm. b) Nařízení Evropského parlamentu a Rady (EU) 2016/679 (dále jen „GDPR“) - zpracování je nezbytné pro splnění smlouvy, jejíž smluvní stranou je subjekt údajů, nebo pro provedení opatření přijatých před uzavřením smlouvy na žádost tohoto subjektu údajů. Pro účely zabezpečení rezervace služeb výše uvedeným způsobem je poskytnutí Vašich osobních údajů povinné, v opačném případě není možné Váš požadavek realizovat, v této souvislosti nedochází k automatizovanému rozhodování, ani profilování. <br>
                  <br>Rádi Vás budeme informovat o službách obdobných Vaší objednávce a akcích organizovaných v KOZLOVNA provozovnách. Jako zákazník konceptu KOZLOVNA můžete proto očekávat zpracování Vašich osobních údajů, které jste nám poskytl/a. Toto zpracování budeme provádět z důvodu oprávněného zájmu dle čl. 6 odst. 1 písm. f) GDPR vykonávat marketing na předměty našeho podnikání a Vaše údaje budeme využívat pro účely přímého marketingu (při rozesílce obchodních sdělení). Obchodní sdělení Vám budeme zasílat po dobu 3 let od poslední Vaší objednávky služeb konceptu KOZLOVNA nebo do doby, kdy zasílání obchodních sdělení odmítnete prostřednictvím odkazu uvedeném v každém zaslaném obchodní sdělení nebo proti takovému zpracování osobních údajů podáte námitku. Námitku proti přímému marketingu prováděnému na základě oprávněného zájmu společných správců můžete kdykoli zaslat na (personaldataprotection@eu.asahibeer.com). Pokud si již nyní nepřejete, abychom Vám obchodní sdělení začali zasílat, zaškrtněte výše uvedené pole. <br>
                  <br>Vaše osobní údaje mohou být poskytnuty následujícím příjemcům: smluvním partnerům správce 1 a správce 2 podílejícím se na správě elektronických dat, na organizačním a technickém zabezpečení zasílání marketingových informací, jakož i subjektům, kterým poskytnutí osobních údajů vyplývá správci ze zákona. V zájmu přizpůsobení marketingových nabídek Vašim individuálním potřebám správce na základě historie Vašich objednávek může využít profilování pro personalizované marketingové nabídky. Při vytváření personalizovaných marketingových nabídek budeme využívat cookies související s provozem stránek www.kozlovna.cz. Více o cookies naleznete <a href="https://pilsnerka.pilsner-urquell.cz/docs/podminky-uziti-cz.pdf">zde</a>. <br>
                  <br>Správce nemá v úmyslu předat osobní údaje do třetí země nebo mezinárodní organizaci. Jakožto subjekt údajů – fyzická osoba jste obecně za podmínek uvedených v GDPR oprávněn/a nás požádat o přístup k Vašim údajům, o jejich opravu, přenos, výmaz, podat námitku, případně požádat o omezení zpracování. Všechny žádosti můžete uplatňovat e-mailem na adrese pověřené osoby pro GDPR: personaldataprotection@eu.asahibeer.com. Domníváte-li se, že jsou Vaše údaje zpracovávány v rozporu s právními předpisy, máte právo také podat stížnost u Úřadu pro ochranu osobních údajů. Více informací o nakládání s osobními údaji a Vašich právech naleznete <a href="https://pilsnerka.pilsner-urquell.cz/docs/smernice_o_ochrane_soukromi%CC%81_PP_CZ.pdf">zde</a>. <br>
              </p>
              </div>
            </transition>
          </div>



      <button type="submit" class="formbutton">Vytvořit rezervaci</button>
    </form>
      </div>
      <img id="menu" class="hero-menu" src="../assets/form-hero.png" alt="">
    </section>
</template>

<script>
import { ref, onValue, set } from 'firebase/database';
import {database} from '../firebase/firebaseInit.js';
import firebase from 'firebase/app';
import 'firebase/functions';
import dayjs from 'dayjs';

export default {
  
  data() {
    return {
      reservation: {
        date: '',
        time: '',
        seats: 0,
        firstName: '',
        phone: '',
        email: '',
        check: '',
        showError: false,
      },
      telephoneNumber: '',
      bookedDates: [],
      reservedSlots: [],
      number: 1,
      showText: false,
    };
  },
  computed: {
    availableTimes() {
      const startTime = 9;
      const endTime = 23;
      const times = [];
      const currentTime = new Date().getHours();
      
      for (let i = startTime; i <= endTime; i++) {
        const time = `${i.toString().padStart(2, '0')}:00`;

        if (this.reservedSlots.indexOf(time) === -1) {
          times.push(time);
        }
      }

      return times;
    },
    formattedDate() {
    return dayjs(this.reservation.date).format('DD.MM.YYYY');
    },
  },
  mounted() {
    const bookingsRef = ref(database, 'bookings');
    onValue(bookingsRef, (snapshot) => {
      const bookings = snapshot.val();
      if (bookings) {
        this.bookedDates = Object.keys(bookings);
      }
    });
  },
    methods: {
      incrementValue() {
      if (this.reservation.seats < 20) {
        this.reservation.seats++;
      }
    },
    decrementValue() {
      if (this.reservation.seats > 1) {
        this.reservation.seats--;
      }
    },
      formatTelephoneNumber() {
      this.reservation.phone = this.reservation.phone.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
    },
      async makeReservation() {
        if (!this.showError){
          const checkboxValue = this.reservation.check ? 'Neposílat' : 'Posílat';
        this.reservedSlots.push(this.reservation.date);
        const reservationRef = ref(database, `bookings/${this.reservation.date}`);
        set(reservationRef, {
          date: this.formattedDate,
          time: this.reservation.time,
          seats: this.reservation.seats,
          firstName: this.reservation.firstName,
          phone: this.reservation.phone,
          email: this.reservation.email,
          check: checkboxValue,
        }).then(() => {
          const reservationData = {
              date: this.formattedDate,
              time: this.reservation.time,
              seats: this.reservation.seats,
              firstName: this.reservation.firstName,
              phone: this.reservation.phone,
              email: this.reservation.email,
              check: checkboxValue
            };
            fetch('https://us-central1-galaxie-dc1a3.cloudfunctions.net/sendReservationEmail', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(reservationData),
            })
              .then((response) => {
                if (response.ok) {
                  console.log('Reservation email sent successfully');
                  console.log(reservationData);
                  // ... Handle any success actions or UI updates ...
                } else {
                  console.error('Error sending reservation email:', response.statusText);
                  // ... Handle error cases or display error message ...
                }
              })
              .catch((error) => {
                console.error('Error sending reservation email:', error);
                // ... Handle error cases or display error message ...
              });
            alert('Rezervace byla úspěšně odeslána!');
            this.clearForm();
            
          })
          .catch((error) => {
            console.error(error);
            alert('Failed to make reservation. Please try again.');
          });
          }
          else{
            this.showError = true;
          }
          

        
    },
    isTimeDisabled(time) {
    const currentDate = new Date();
    const selectedDate = new Date(this.reservation.date);

    // Disable if selected date is in the past or the current time is past 14:00 (2 PM)
    if (selectedDate < currentDate || (selectedDate.getTime() === currentDate.getTime() && currentDate.getHours() >= 14)) {
      return true;
    }

    // You can add additional logic here if needed to disable specific times

    return false;
  },
    clearForm() {
        this.reservation = {
          date: '',
          time: '',
          seats: 1,
          firstName: '',
          phone: '',
          email: '',
        };
      },
    isDateDisabled(date) {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0); // Set hours to 00:00:00 for comparison
      const selectedDate = new Date(date);
      selectedDate.setHours(0, 0, 0, 0);

      // Disable if selected date is in the past or already reserved
      return selectedDate < currentDate || this.reservedSlots.indexOf(date) !== -1;
    },
    isReservationDisabled() {
      const currentDate = new Date();
      const selectedDate = new Date(this.reservation.date);

      // Disable reservation if the selected date is the same day and it's past 14:00
      if (
        selectedDate.getDate() === currentDate.getDate() &&
        currentDate.getHours() >= 14
      ) {
        return true;
      }
      return false;
      },
    },
  }


  /* Posicion nombre de seccion */
document.querySelectorAll('.writing :is(input , textarea)').forEach(writinginput => {
  writinginput.addEventListener('input', () => {
    if (writinginput.value.trim() !== '') {
      writinginput.classList.add('has-text');
    } else {
      writinginput.classList.remove('has-text');
    }
  });
});


/* Alto de textarea */
document.querySelectorAll('.textarea textarea').forEach(textarea => {
  textarea.addEventListener('input', () => {
    textarea.style.height = '1em';
    const scrollHeight = textarea.scrollHeight;
    textarea.style.height = `${scrollHeight}px`;
  });
});

</script>

 <style lang="scss" scoped>



.clickable-text{
  &:hover{
    text-decoration: underline;
    cursor: pointer;
  }
}


.underline{
  left: 45%;
    position: absolute;
}
.sdeleni{
  font-size: 12px;
  a{
    color: #ffffb5;
  }
}
.formbutton{
    text-transform: uppercase;
	background-color:#000000;
	border-radius:28px;
	border:2px solid #ffffb5;
	cursor:pointer;
	color:#ffffff;
	padding:16px 31px;
    span{
        font-weight: 500;
        font-size: 20px;
    }
    img{
        padding: 0;
        padding-right: 20px;
    }
}

.column, .form-container{
  position: absolute;
}
.form-container{
  margin-top: 30em;
  left: 16%;
}


.po14{
  
  color: rgb(255, 0, 0);
  letter-spacing: 0.5px;
  background-color: rgba(211, 211, 211, 0.096);
  border-radius: 5%;
  padding: 20px;
  margin-right: 18px;
}

.column {
margin-left: 50em;
  padding: 10px;
}
.hero-menu{
  width: 100%;
}


.error-message{
  font-weight: 800;
  font-size: 20px;
  color: red;
}


.confirm-message{
  font-weight: 800;
  font-size: 20px;
  color: lightgreen;
}



.title{
  left: 40%;
}


.balon{
  position: absolute;
  left: 55%;
  width: 650px;
}
section h2{
  font-size: 40px;
  text-transform: uppercase;
}

#kontakt{
  color: white;
}

.kontakt{
  background-color: #003DA5;
}

::placeholder{
  color: black;
}

.input-style::placeholder{
  color: gray;
  opacity: 0.4;
}

.input-style, select, textarea {
  font-size: 18px;
  display: flex;
  width: 500px;
  height: 55px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-sizing: border-box;
  resize: vertical;
}

input[type=submit] {
  font-weight: 600;
  background-color: white;
  color: black;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

#kontakt {
  border-radius: 5px;
  background-color: black;
}


.checkbox{
  color: white;
  padding: 10px;
}


  @media only screen and (max-width: 768px){
    input[type=text],[type=email], select, textarea {
      max-width: 650px;
    }
  }
  @media only screen and (max-width: 576px){
    input[type=text],[type=email], select, textarea {
      width: 368px;
    }
  }
  @media only screen and (max-width: 400px){
    
    
    input[type=text],[type=email], select, textarea {
      max-width: 300px;
    }
    section h2{
      font-size: 37px;
    }
    .kontakt{
      padding: 30px;
    }
  }



  /* Estilos pagina */

/* Estilos personalizados */
form {
  width: 100%;
  max-width: 800px;
}
form > div {
  margin: .7em 0;
  text-align: left;
}
input, textarea {
  background: transparent;
  border: 0;
  outline: 0;
  font-size: 1em;
  color: white;
}


/* text, password, textarea y number */
.writing {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 1em;
}
.writing :is(input , textarea) {
  border: 0;
  padding: 10px 0;
}
.writing :is(input , textarea) ~ label {
  position: absolute;
  top: calc(10px + 0.8em);
  left: 0px;
  pointer-events: none;
  transition: all .2s ease;
  color: #ababab;
}
.writing :is(input , textarea):focus ~ label,
.writing :is(input , textarea).has-text ~ label{
  top: 0px;
  font-size: .9em;
}
.writing :is(input , textarea) ~ span {
  content: "";
  height: 1px;
  width: 100%;
  background: white;
  display: flex;
  justify-content: center;
}
.writing :is(input , textarea):invalid ~ span {
  background: white;
}
.writing :is(input , textarea) ~ span:before {
  content: "";
  display: block;
  height: 2px;
  width: 0%;
  background: #ffffb5;
  transition: width .5s ease;
}
.writing :is(input , textarea):focus ~ span:before {width: 100%;}
/* text y password */
.writing:is(.text , .password) :is(input , input ~ span) {width: 400px;}
/* textarea */
.textarea textarea {
  overflow: auto;
  min-height: calc(1em + 20px);
  height: 1.2em;
  overflow: hidden;
  resize: none;
}


/* number */
.number {margin-top: 2em;}
.number .titulo {font-size: .9em; color: white;}
.number .numero {
  margin-top: 1em;
  user-select: none;
  display: flex;
}
.number .numero > * {padding: 10px;}
.number .numero input {
  text-align: center;
  background: #1c1c1c;
  min-width: 150px;
}
.number .numero input::-webkit-inner-spin-button {appearance: none;}
.number .numero label {
  cursor: pointer;
  width: calc(1em + 20px);
  text-align: center;
  background: lightgray;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.number .numero label:hover {background: #ffffb5;}
.number .numero label:active {background: #225588;}
.number .numero label svg {width: 75%;}
.number .numero .decrement {border-radius: 10px 0 0 10px;}
.number .numero .increment {border-radius: 0 10px 10px 0;}


/* Date*/
.date, .time {
  display: flex;
  flex-direction: column;
  position: relative;  
  margin-top: 1em;
}
.date .titulo {font-size: .9em; color: white;}
.date .fecha, .time .tiempo {width: min-content;}
:is(.date , .time) input {
  font-size: 20px;
  width: 500px;
  height: 60px;
  padding: 10px 0;
}
:is(.date , .time) input::-webkit-calendar-picker-indicator {filter: invert(1);}
.date label, .time .titulo {
  position: absolute;
  top: 0px;
  left: 0px;
  pointer-events: none;
  transition: all .2s ease;
  color: #ababab;
}
:is(.date , .time) input ~ span {
  content: "";
  height: 1px;
  width: 100%;
  background: white;
  display: flex;
  justify-content: center;
}
:is(.date , .time) input:invalid ~ span {
  background: white;
}
:is(.date , .time) input ~ span:before {
  content: "";
  display: block;
  height: 2px;
  width: 0%;
  background: #ffffb5;
  transition: width .5s ease;
}
:is(.date , .time) input:focus ~ span:before {width: 100%;}


/* Selector */
.switch .view > span > span {
  content: "";
  height: 135%;
  aspect-ratio: 1/1;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #FFF;
  border-radius: 100%;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.switch #check-menu:checked ~ .view > span > span {
  background-color: #3399FF;
}
/* Hover */
.switch .view:hover > span:before {
  content: "";
  height: 265%;
  aspect-ratio: 1/1;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  background-color: #ffffff14;
  border-radius: 100%;
  opacity: 0;
}
.switch .view:hover > span:before {opacity: 1;}
.switch #check-menu:checked ~ .view > span:before {
  background-color: #3399ff14;
}
/* Active */
.switch .view > span:after {
  content: "";
  height: 0px;
  aspect-ratio: 1/1;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  background-color: #ffffff26;
  border-radius: 100%;
  opacity: 0;
  transition: height 0s ease .5s, opacity .5s ease 0s;
}
.switch .view:hover:active > span:after {
  height: 265%;
  opacity: 1;
  transition: height .5s ease, opacity 0s ease;
}
.switch #check-menu:checked ~ .view:hover:active > span:after {
  background-color: #3399ff26;
}




/* select */
.select {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 3em;
}
.select .titulo {font-size: .9em; color: white;}
.select .view {
  position: relative;
  width: fit-content;
}
.select .view select {
  appearance: none;
  width: 500px;
  padding: 0.5em 0.75em;
  padding-right: 2em;
  background: black;
  border-radius: 5px;
  color: #ffffb5;
  font-size: 1em;
  border: 1;
  outline: 1;
  cursor: pointer;
}
.select .view select option {
  background: black;
}
.select .view select option:checked {
  background: #225588;
}
.select .view svg {
  fill: white;
  width: 1em;
  height: 1em;
  position: absolute;
  right: .5em;
  top: 50%;
  transform: translate(0%, -50%) rotate(0deg);
  pointer-events: none;
  transition: all .5s ease;
}
.select .view select.select-open ~ svg {
  transform: translate(0%, -50%) rotate(180deg);
}







/* range */


/* color */
.color input {
  width: 1.75em;
  height: 1.75em;
  cursor: pointer;
}


/* submit */
.submit input {
  padding: .5em 1em;
  background: #3399ff;
  border-radius: 5px;
  cursor: pointer;
}
.submit input:active {
  background: #225588;
}



@media screen and (max-width: 1450px) {
  .column{
    margin-left: 45em;
  }


  @media screen and (max-width: 1350px) {
  .column{
    margin-left: 37em;
    }
  }


  @media screen and (max-width: 1100px) {
  .column{
    margin-left: 10em;
    margin-top: -23em;
    .writing:is(.text , .password) :is(input , input ~ span) {width: 500px;}
  }

    .date, .select, .number{
      margin-top: 5em;
      margin-left: 10.7em;
    }
  }
  .formbutton{
    margin-bottom: 2em;
  }
}

@media screen and (max-width: 1024px) {
  #kontakt{
    margin-top: 20em;
  }
  }

@media screen and (max-width: 900px) {
  #kontakt{
    padding-top: 24em;
  }
}


@media screen and (max-width: 900px) {

  .form-container{
    left: 0;
  }

  input[type="checkbox"]{
    margin-left: 11em;
  }

  .clickable-text{
    margin-left: 2em;
    padding-right: 40px;
    padding-left: 140px;
  }

  .formbutton{
    margin-left: 13em;
  }

  .title{
    left: 28%;
  }
}

@media screen and (max-width: 480px) {
  .po14{
    position: absolute;
  margin-top: -350px;
  margin-left: 100px;
  }
  
  .column{
    .writing:is(.text , .password) :is(input , input ~ span) {width: 240px;}
    
  }
  .select .view select {
    width: 240px;
    }
    :is(.date , .time) input {
  font-size: 20px;
  width: 240px;
  height: 60px;
  padding: 10px 0;
  }

  .underline{
    margin-top: 6.5em;
    left: 33.5%;
  }

  .form-container{
    left: -74px;
  }

  #menu{
    display: none;
  }

  .sdeleni{
    padding-left: 90px;
    max-width: 450px;
  }
}

@media screen and (max-width: 430px) {
  input[type="checkbox"]{
    margin-left: 7em;
  }
  .po14{
    position: absolute;
  margin-top: -350px;
  margin-left: 130px;
  }
  .column{
    margin-top: -20em;
  }
}

@media screen and (max-width: 384px) {
  input[type="checkbox"]{
    margin-left: 5em;
  }
}


@media screen and (max-width: 375px) {
  input[type="checkbox"]{
    margin-left: 7em;
  }

  .form-container{
    left: -95px;
  }

  .sdeleni{
    padding-left: 115px;
    padding-right: 18px;
  }
}


@media screen and (max-width: 320px) {
  .number .numero input {
  text-align: center;
  background: #1c1c1c;
  min-width: 80px;
}
}
</style>